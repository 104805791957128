@import "~antd/dist/antd.css";

.app {
  padding: 1rem;
  max-width: 800px;
  margin: auto;
}

.totalAmount {
  background-color: aliceblue;
  padding: 15px;
  border-radius: 10px;
}
.messageBox {
  font-size: 1.25rem;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectedService {
  background-color: aliceblue;
}
